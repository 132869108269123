import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Spacer = () => {
  return (
    <StaticImage 
      src="../images/plus-icon.png" 
      placeholder="tracedSVG"
      alt=""
    />
  )
}

export default Spacer