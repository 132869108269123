import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { P, PageHeading, TextBlockHeading } from "../components/typography"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center mb-2">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    },
  },
}

const pubToPubLink = {
  "Therapeutic Advances in Urology": "https://journals.sagepub.com/home/tau",
  "Korean Journal of Urology": "http://www.urology.or.kr/eng/",
  "Canadian Journal of Urology": "https://www.canjurol.com/",
  "European Association of Urology": "https://uroweb.org/",
  "Journal of Urology": "https://www.auajournals.org/journal/juro",
  "BJU International": "https://www.bjuinternational.com/",
  "Urology Times": "https://www.urologytimes.com/",
}

const Science = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulJournalArticles(filter: {conclusion: {raw: {ne: null}}}) {
        edges {
          node {
            authors
            articleTitle
            publication
            link
            file {
              localFile {
                publicURL
              }
            }
            previewImage {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 90
              )
              title
            }
            conclusion {
              raw
            }
          }
        }
      }
    }
  `)

  /*const journals = [
    {
      name: 'Men\'s Health',
      link: 'https://www.menshealth.com/sex-women/a19545512/extracorporeal-shock-wave-therapy/'
    },
    {
      name: 'NCBI',
      link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3607492/'
    },
    {
      name: 'Journal of Urology',
      link: 'https://www.auajournals.org/doi/full/10.1016/j.juro.2017.02.3145#s0015'
    },
    {
      name: 'Sagepub Journals',
      link: 'https://journals.sagepub.com/doi/full/10.1177/1756287212470696'
    },
    {
      name: 'Science Direct',
      link: 'https://www.sciencedirect.com/science/article/pii/S2050116116300423'
    },
    {
      name: 'Fox News',
      link: 'https://www.foxnews.com/health/shock-treatment-may-improve-erectile-dysfunction'
    },
    {
      name: 'Vice Article',
      link: 'https://www.vice.com/en_us/article/evggdw/the-hard-science-of-using-shock-therapy-for-boners'
    },
    {
      name: 'Online Library',
      link: 'https://onlinelibrary.wiley.com/doi/full/10.1046/j.1464-410x.2001.02194.x'
    },
  ]*/

  return (
    <Layout>
          <SEO 
            title="Science Behind Wave Therapy" 
          />
          <div className="flex justify-center">
            <div
              className="text-center px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-5xl" 
            >
              <PageHeading>Science</PageHeading>
              <Spacer />
              <P margin="mb-4">
                Research continues to demonstrate 
                the clinical efficacy of Acoustic Wave Therapy.
              </P>
              <P margin="mb-6">
                We've collected a number of articles that highlight the benefits of 
                the treatment we provide.
              </P>
              <TextBlockHeading>Featured Research</TextBlockHeading>
              <div className="w-full text-left">
                {data.allContentfulJournalArticles.edges.map((article, index) => {
                  const { node } = article
                  const { 
                    authors, 
                    articleTitle, 
                    file, 
                    link, 
                    previewImage, 
                    publication,
                    conclusion,
                  } = node
                  const bottomBorder = index !== data.allContentfulJournalArticles.edges.length - 1
                  return (
                    <div key={`pdf-${index}`} className={`px-2 mb-6 mt-2 pb-6 text-gray-900 ${ bottomBorder ? `border-b-2` : ``}`}>
                      <a href={file.localFile.publicURL} target="_blank" rel="noopener noreferrer">
                        <GatsbyImage
                          image={previewImage.gatsbyImageData}
                          alt={previewImage.title}
                          style={{ width: 200, height: 200}}
                          className="rounded-lg border-2 shadow-lg float-left mr-4 mb-3"
                        />
                      </a>
                      <div className="md:ml-4">
                        <a href={file.localFile.publicURL} target="_blank" rel="noopener noreferrer">
                          <div className="text-md md:text-lg font-medium">
                            {articleTitle}
                          </div>
                        </a>
                        <div className="text-sm mb-2 text-gray-500">
                          <span>
                            {`${authors.map((author) => { 
                              if (author !== "John Doe") { 
                                return ' ' + author
                              } else {
                                return null 
                              }
                            })} - `}
                          </span>
                          <span>
                            <a 
                              className="text-blue-500 font-medium"
                              href={pubToPubLink[publication]} 
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              {publication}
                            </a>
                          </span>
                        </div>
                        <div>
                          {renderRichText(conclusion, options)}
                        </div>
                      </div>
                      <div className="md:ml-4 flex w-full text-sm text-gray-400">
                        <div className="uppercase mr-2 text-blue-500">
                          <a href={file.localFile.publicURL} target="_blank" rel="noopener noreferrer">Read Fulltext</a>
                        </div>
                        {` | `}
                        <div className="uppercase ml-2 text-blue-500">
                          <a href={link} target="_blank" rel="noopener noreferrer">View on Pubmed</a>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {/*<div className="mb-6 md:flex flex-wrap lg:px-16 xl:px-32">
                {journals.map((journal, index) => (
                  <a 
                    rel="noopener noreferrer" 
                    target="_blank" 
                    href={journal.link}
                    key={`throwaway-${index}`}
                    className="md:w-1/2"
                  >
                    <p className="text-blue-500 font-medium text-lg mb-1">{journal.name}</p>
                  </a>
                ))}
              </div>
              <div className="flex flex-wrap justify-center">
                {data.allContentfulJournalArticles.edges.map((article, index) => {
                  return (
                    <div key={`pdf2-${index}`} className="px-2 mb-6" style={{ flex: `0 1 22%` }}>
                      <a href={article.node.file.localFile.publicURL} target="_blank" rel="noopener noreferrer">
                        <GatsbyImage
                          image={article.node.previewImage.gatsbyImageData}
                          alt={article.node.previewImage.title}
                          style={{ width: 200, height: 200}}
                          className="rounded-lg border-2 shadow-lg"
                        />
                      </a>
                    </div>
                  )
                })}
              </div>*/}
            </div>
          </div>
    </Layout>
  )
}

export default Science
