import React from 'React';
import { Link } from 'gatsby';

export const P = ({ padding, margin, children }) => {
  return (
    <p className={`text-lg text-gray-900 leading-normal ${padding ? padding : ''} ${margin ? margin : ''}`}>{children}</p>
  );
}

export const TextLink = ({ to, children }) => {
  return (
    <Link to={to} className="text-blue-500 font-medium">{children}</Link>
  )
}

export const TextBlockHeading = ({ wide, semibold, mb, children }) => {
  let extraClasses = ""
  if (!semibold) {
    extraClasses = "font-bold"
  } else {
    extraClasses = "font-semibold"
  }

  if (wide) {
    extraClasses = extraClasses + " tracking-wide"
  }

  if (mb) {
    extraClasses = extraClasses + " mb-1"
  }

  return (
    <h2 className={`text-2xl text-gray-900 font-bold leading-snug ${extraClasses}`}>{children}</h2>
  );
}

export const PageHeading = ({ children }) => {
  return (
    <h1 className="text-3xl font-bold text-gray-900">
      {children}
    </h1>
  )
} 

export const BlockQuote = ({ children }) => {
  return (
    <div className="text-3xl px-4 sm:px-8 lg:px-16 mb-4 text-blue-500 font-medium">
      “...{children}...”
    </div>
  )
}

export const BlogP = ({ children }) => {
  return (
    <p className={`text-lg text-gray-900 leading-normal mb-4`}>{children}</p>
  )
}

export const BlogHeading = ({ children }) => {
  return (
    <h2 className="text-2xl text-gray-900 font-bold leading-snug mb-2">{children}</h2>
  )
}